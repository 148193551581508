import Axios from 'axios'

//域名设置
const api_url = 'https://fydj.kjcxchina.com';

//添加请求拦截器        跟这个 是同时进行的
Axios.interceptors.request.use(config => { //在发送请求之前执行方法
	if(sessionStorage.getItem('token')){
		config.headers['token'] = sessionStorage.getItem('token');
	}
	
	return config;
}, error => { //请求错误时执行方法
	return Promise.reject(error);
})

//添加响应拦截器
Axios.interceptors.response.use(response => { //响应数据执行方法
	return response.data
});

Axios.defaults.baseURL = api_url + "/api";

export default Axios;