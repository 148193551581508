import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible'
import Axios from '@/utils/axios'

import 'vant/lib/index.css'
import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import { Uploader } from 'vant';
import { Toast } from 'vant';


Vue.use(Uploader);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Toast);
Vue.config.productionTip = false
Vue.prototype.$axios = Axios; //引用axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
