<template>
  <div class="index visual-area-height page-bg">
    <div class="logo"><img src="@/assets/index_logo.png" alt="" /></div>
    <div class="title_text">
      <p class="title">疫情防控信息</p>
      <p class="tips">登记系统</p>
    </div>
    <div class="form">
      <ul v-if="showIndex == '1'">
        <li>
          <label>姓名：</label>
          <input type="text" v-model="userInfo.name" placeholder="请输入姓名" />
          <!-- <p class="tips">请输入姓名</p> -->
        </li>
        <li>
          <label>手机号：</label>
          <input
            type="text"
            v-model="userInfo.mobile"
            maxlength="11"
            placeholder="请输入手机号"
          />
          <!-- <p class="tips">请输入手机号</p> -->
        </li>
      </ul>
      <ul v-if="showIndex == '2'">
        <li>
          <label>账号：</label>
          <input
            type="text"
            v-model="userInfo.mobile"
            max="11"
            placeholder="请输入账号"
          />
          <!-- <p class="tips">请输入账号</p> -->
        </li>
        <li>
          <label>密码：</label>
          <input
            type="password"
            v-model="userInfo.password"
            placeholder="请输入密码"
          />
          <!-- <p class="tips">请输入密码</p> -->
        </li>
      </ul>
    </div>
    <div class="btn cursor" @click="loginBtn">登录</div>
    <div class="change_text" v-if="showIndex == '1'" @click="changeWay('2')">
      管理员登录
    </div>
    <div class="change_text" v-if="showIndex == '2'" @click="changeWay('1')">
      个人登录
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      showIndex: "1",
      userInfo: {
        name: "",
        mobile: "",
        password: "",
      },
    };
  },
  mounted() {},
  methods: {
    changeWay(num){
      this.showIndex = num
      this.userInfo={
        name: "",
        mobile: "",
        password: "",
      }
    },
    //
    loginBtn() {
      if (this.showIndex == "1") {
        let data = {
          name: this.userInfo.name,
          mobile: this.userInfo.mobile,
          type: "1",
        };
        if (!this.userInfo.name) {
          this.$toast("请输入姓名");
          return;
        }
        if (!this.userInfo.mobile) {
          this.$toast("请输入手机号");
          return;
        }
        //个人登录
        this.$axios.post(`/User/login`, data).then((res) => {
          if (res.code == 200) {
            this.$router.push("/perPage/register");
            localStorage.removeItem("userInfo");

            localStorage.setItem("userInfo", JSON.stringify(res.data.userinfo));
            console.log(JSON.stringify(localStorage.getItem("userInfo")));
          } else {
            this.$toast(res.msg);
          }
        });
      }
      if (this.showIndex == "2") {
        // 管理员登录
        let data = {
          mobile: this.userInfo.mobile,
          password: this.userInfo.password,
          type: "2",
        };
        if (!this.userInfo.mobile) {
          this.$toast("请输入手机号");
          return;
        }
        if (!this.userInfo.password) {
          this.$toast("请输入密码");
          return;
        }
        this.$axios.post(`/User/login`, data).then((res) => {
          console.log(res);
          if (res.code == 200) {
            localStorage.removeItem("userInfo");

            localStorage.setItem("userInfo", JSON.stringify(res.data.userinfo));
            console.log(JSON.stringify(localStorage.getItem("userInfo")));
            this.$router.push("/adminPage/meet_list");
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.logo {
  width: 67px;
  // height: 69px;
  margin: 52px auto 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.title_text {
  margin-top: 18px;
  .title {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }
  .tips {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
  }
}
.form {
  margin-top: 46px;
  box-sizing: border-box;
  padding: 0 20px;
  ul {
    li {
      width: 335px;
      height: 44px;
      background: #ffffff;
      // background: #444;
      border-radius: 49px 49px 49px 49px;
      margin: 0 auto 20px;
      label {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 44px;
        margin-left: 20px;
      }
      input {
        width: 70%;
        height: 90%;
        font-size: 14px;
      }
      // p.tips{
      //   color: ;
      // }
    }
  }
}
.btn {
  width: 335px;
  height: 44px;
  margin: 10px auto 0;
  background: linear-gradient(180deg, #fff697 0%, #ffea0d 100%);
  border-radius: 38px 38px 38px 38px;
  opacity: 1;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  font-weight: 600;
  color: #ee0903;
}
.change_text {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin-top: 18px;
}
</style>