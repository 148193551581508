import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
   //登记记录
   {
    path: '/perPage/register',
    name: '/perPage/register',
    component: () => import('../views/perPage/register.vue')
  },
   //打卡
   {
    path: '/perPage/punchIn',
    name: '/perPage/punchIn',
    component: () => import('../views/perPage/punchIn.vue')
  },
 
   //详情
   {
    path: '/perPage/detail',
    name: '/perPage/detail',
    component: () => import('../views/perPage/detail.vue')
  },
   //会议列表
   {
    path: '/adminPage/meet_list',
    name: '/adminPage/meet_list',
    component: () => import('../views/adminPage/meet_list.vue')
  },
   {
    path: '/adminPage/punch_list',
    name: '/adminPage/punch_list',
    component: () => import('../views/adminPage/punch_list.vue')
  },
   {
    path: '/adminPage/service_info',
    name: '/adminPage/service_info',
    component: () => import('../views/adminPage/service_info.vue')
  },
 
]

const router = new VueRouter({
  routes
})

export default router
